import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Add_department, edit_department_data } from "../../Redux/services/Department";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const DepartmentModal = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const getEditDepartMentID = useSelector(state => state?.department?.departmentEditId);
  const getEditDepartMentData = useSelector(state => state?.department?.EditDepartmentData);

  const onSubmit = async (data) => {
    try {
      if (getEditDepartMentID) {
        data.department_id = getEditDepartMentID
      }
      const response = await dispatch(Add_department(data));
      if (response?.payload?.status === true) {
      }
    } catch (error) {
      console.error("Error during add home:", error);
    }
  };

  useEffect(() => {
    if (getEditDepartMentID) {
      dispatch(edit_department_data(getEditDepartMentID))
    }
    setValue('name', getEditDepartMentData?.name);
    setValue('is_active', getEditDepartMentData?.is_active === true ? '1' : '0');
  }, [getEditDepartMentID])
  return (
    <>
      {/* Add Department Modal */}
      <div
        id="add_department"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Department</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...register("name", { required: "Department Name is required" })} />
                  {errors.name && (
                    <small className="text-danger">{errors.name.message}</small>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Is Active</label>
                  <select
                    className="form-control form-select"
                    {...register("is_active", {
                      required: "Is Active is required"
                    })}
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.is_active && (
                    <small className="text-danger">{errors.is_active.message}</small>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Department Modal */}
      {/* Edit Department Modal */}
      <div
        id="edit_department"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Department</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...register("name", { required: "Department Name is required" })} />
                  {errors.name && (
                    <small className="text-danger">{errors.name.message}</small>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Is Active</label>
                  <select
                    className="form-control form-select"
                    {...register("is_active", {
                      required: "Is Active is required"
                    })}
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.is_active && (
                    <small className="text-danger">{errors.is_active.message}</small>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Department Modal */}
    </>
  );
};

export default DepartmentModal;
