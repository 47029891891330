import { createSlice } from "@reduxjs/toolkit";
import { get_all_active_departments, Add_department, get_department_data, Department_delete, edit_department_data } from "../services/Department";

const initialState = {
    isLoading: false,
    error: null,
    token: localStorage.getItem('token'),
    errorMsg: '',
    ActiveDepartments: [],
    AllDepartments: [],
    EditDepartmentData: [],
    departmentEditId: {}
};

export const DepartmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        setDepartMentEditID: (state, action) => {
            state.departmentEditId = action?.payload;
        },
    },
    extraReducers: (builder) => {
        // // ========== Add_department ============== //
        builder.addCase(Add_department.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_department.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMsg = action?.payload;
            state.logUserID = action?.payload?.data?.data?.id
        });
        builder.addCase(Add_department.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
            state.errorMsg = action?.payload;
        });
        // ===========get all department data ============== //
        builder.addCase(get_department_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_department_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllDepartments = action?.payload?.data
        });
        builder.addCase(get_department_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get active department data ============== //
        builder.addCase(get_all_active_departments.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_active_departments.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ActiveDepartments = action?.payload?.data
        });
        builder.addCase(get_all_active_departments.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========deactivate homes ============== //
        builder.addCase(Department_delete.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Department_delete.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Department_delete.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit historian data ============== //
        builder.addCase(edit_department_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_department_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditDepartmentData = action?.payload?.data
        });
        builder.addCase(edit_department_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }

});
export const { setDepartMentEditID } = DepartmentSlice?.actions;
export default DepartmentSlice.reducer;