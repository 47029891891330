import React, { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { get_all_active_departments } from "../../Redux/services/Department";
import { Add_designation, edit_designation_data } from "../../Redux/services/Designation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const AddDesingnationModelPopup = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const ActiveDepartments = useSelector(state => state?.department?.ActiveDepartments);
  const getEditDesignationID = useSelector(state => state?.designation?.designationEditId);
  const getEditDesignationData = useSelector(state => state?.designation?.EditDesignationData);
  console.log('getEditDesignationID', getEditDesignationData);

  useEffect(() => {
    dispatch(get_all_active_departments());
    if (getEditDesignationID) {
      dispatch(edit_designation_data(getEditDesignationID))
    }
    setValue('name', getEditDesignationData?.name);
    setValue('department_id', getEditDesignationData?.department_id);
    setValue('is_active', getEditDesignationData?.is_active === true ? '1' : '0');
  }, [getEditDesignationID]);
  const onSubmit = async (data) => {
    try {
      if (getEditDesignationID) {
        data.designation_id = getEditDesignationID
      }
      const response = await dispatch(Add_designation(data));
      if (response?.payload?.status === true) {
      }
    } catch (error) {
      console.error("Error during add designation:", error);
    }
  };
  return (
    <>
      <div
        id="add_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Designation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Department <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select"
                      {...register("department_id", {
                        required: "Department is required", onChange: (e) => {
                          setValue('department_id', e.target.value);
                        },
                      })}
                    >
                      <option value="">Select</option>
                      {
                        ActiveDepartments && ActiveDepartments?.map((ele) => (
                          <option value={ele?.id}>{ele?.name}</option>
                        ))
                      }
                    </select>
                    {errors.department_id && (
                      <small className="text-danger">{errors.department_id.message}</small>
                    )}
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Designation Name <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" type="text" {...register("name", {
                      required: "Designation Name is required"
                    })} />
                    {errors.name && (
                      <small className="text-danger">{errors.name.message}</small>
                    )}
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">Is Active</label>
                    <select
                      className="form-control form-select"
                      {...register("is_active", {
                        required: "Is Active is required"
                      })}
                    >
                      <option value="">Select</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    {errors.is_active && (
                      <small className="text-danger">{errors.is_active.message}</small>
                    )}
                  </div>
                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="edit_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Designation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control form-select"
                    {...register("department_id", {
                      required: "Department is required", onChange: (e) => {
                        setValue('department_id', e.target.value);
                      },
                    })}
                  >
                    <option value="">Select</option>
                    {
                      ActiveDepartments && ActiveDepartments?.map((ele) => (
                        <option value={ele?.id}>{ele?.name}</option>
                      ))
                    }
                  </select>
                  {errors.department_id && (
                    <small className="text-danger">{errors.department_id.message}</small>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...register("name", {
                    required: "Designation Name is required"
                  })} />
                  {errors.name && (
                    <small className="text-danger">{errors.name.message}</small>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Is Active</label>
                  <select
                    className="form-control form-select"
                    {...register("is_active", {
                      required: "Is Active is required"
                    })}
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.is_active && (
                    <small className="text-danger">{errors.is_active.message}</small>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    // data-bs-dismiss="modal"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDesingnationModelPopup;
