import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/Alert";
import { HTTPURL } from "../../Constent/Matcher";
export const login = createAsyncThunk(
    "auth/login",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}login`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                localStorage.setItem('loginUserId', res?.data?.data?.id)
                var now = new Date().getTime();
                localStorage.setItem('setupTime', now.toString());
                customAlert(res?.data?.message, 'success');
            }
            else {
                customAlert(res?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);


export const VerifyOtp = createAsyncThunk(
    "auth/otp-verify",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}otp-verify`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    "auth/forget-password",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}forget-password`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

export const resetPassword = createAsyncThunk(
    "auth/reset-password",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}reset-password`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
