import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { get_employee_data } from "../../../Redux/services/Employee";
import { setEditUserId } from "../../../Redux/features/Common";
import { useDispatch, useSelector } from "react-redux";
import AllEmployeeEditPopup from "../../../components/modelpopup/AllEmployeeEditPopup";
import { BASEURL } from "../../../Constent/Matcher";
const EmployeeList = () => {
  const dispatch = useDispatch();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const AllEmployeeData = useSelector(state => state?.employee?.employeeData);
  console.log('AllEmployeeDataAllEmployeeData', AllEmployeeData);

  const EditIdFunction = (id) => {
    dispatch(setEditUserId(id))
    setEditModalOpen(true)
  }
  useEffect(() => {
    dispatch(get_employee_data());
  }, []);
  const data = AllEmployeeData?.users?.map((item, i) => ({
    id: item?.id,
    name: `${item?.first_name} ${item?.last_name}`,
    phone: item?.phone,
    email: item?.email,
    employee_id: item?.employee_id,
    role: item?.role?.name,
    profile_pic: `${BASEURL}${item.profile_pic}`
  }));
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" className="avatar">
            <img alt="" src={record.profile_pic} />
          </Link>
          <Link to="/profile"> {text} </Link>
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id.length - b.employee_id.length,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Mobile",
      dataIndex: "phone",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.length - b.role.length,
      // render: () => (
      //   <div className="dropdown">
      //     <Link
      //       to="#"
      //       className="btn btn-white btn-sm btn-rounded dropdown-toggle"
      //       data-bs-toggle="dropdown"
      //       aria-expanded="false"
      //     >
      //       Web Developer{" "}
      //     </Link>
      //     <div className="dropdown-menu">
      //       <Link className="dropdown-item" to="#">
      //         Software Engineer
      //       </Link>
      //       <Link className="dropdown-item" to="#">
      //         Software Tester
      //       </Link>
      //       <Link className="dropdown-item" to="#">
      //         Frontend Developer
      //       </Link>
      //       <Link className="dropdown-item" to="#">
      //         UI/UX Developer
      //       </Link>
      //     </div>
      //   </div>
      // ),
    },
    {
      title: "Action",
      sorter: true,
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_employee"
              onClick={() => EditIdFunction(record?.id)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_employee"
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
          />
          {/* /Page Header */}
          <EmployeeListFilter />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <AllEmployeeAddPopup />
        <AllEmployeeEditPopup isOpen={isEditModalOpen} setIsOpen={setEditModalOpen} />
        <DeleteModal Name="Delete Employee" />
      </div>
    </div>
  );
};

export default EmployeeList;
