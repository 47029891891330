import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { forgotPassword } from "../../../Redux/services/User";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
const ForgotPassword = (data) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await dispatch(forgotPassword(data));
      if (response?.payload?.status) {
        navigate("/change-password");
      }
    } catch (error) {
    }
  };
  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/app/main/dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Forgot Password?</h3>
                <p className="account-subtitle">
                  Enter your email to get a password reset link
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-block">
                    <label>Email Address</label>
                    <input className="form-control" type="text"  {...register('email', { required: "Email is required" })} />
                    <span className="text-danger">{errors.email?.message}</span>
                  </div>
                  <div className="input-block text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit">
                      Reset Password
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Remember your password? <Link to="/">Login</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
