import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { get_all_active_departments } from "../../Redux/services/Department";
import { get_all_active_designation } from "../../Redux/services/Designation";
import { getRoles } from "../../Redux/services/common";
import { Add_employee, edit_employee_data } from "../../Redux/services/Employee";
import { useDispatch, useSelector } from "react-redux";
const AllEmployeeEditPopup = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors }, } = useForm();
    // selector data
    const ActiveDepartments = useSelector(state => state?.department?.ActiveDepartments);
    const ActiveDesignation = useSelector(state => state?.designation?.ActiveDesignation);
    const EditUserDetail = useSelector(state => state?.employee?.EditEmployeeList);
    const RolesList = useSelector(state => state?.common?.getRolesData);
    const getEditUserID = useSelector(state => state?.common?.editUserId)
    const [files, setFiles] = useState([]);
    const selectedDepartmentId = watch('department_id');
    const currentDate = new Date();
    // Calculate the date 18 years ago
    let minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - 18);
    const maxDate = currentDate;
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('first_name', data?.first_name);
        formData.append('last_name', data?.last_name);
        formData.append('phone', data?.phone);
        formData.append('password', data?.password);
        formData.append('email', data?.email);
        formData.append('user_name', data?.user_name);
        formData.append('date_of_birth', data?.date_of_birth);
        formData.append('designation_id', data?.designation_id);
        formData.append('department_id', data?.department_id);
        formData.append('is_active', data?.is_active);
        formData.append('role_id', data?.role_id);
        if (getEditUserID?.length > 0) {
            formData.append('user_table_id', getEditUserID);
        }
        const getUserImage = getValues('profile_pic')
        if (files && files.length > 0) {
            formData.append('profile_pic', files[0] ? files[0] : getUserImage);
        }
        try {
            const response = await dispatch(Add_employee(formData));
            if (response?.payload?.status === true) {
                setIsOpen(false)
            }
        } catch (error) {
            console.error("Error during add home:", error);
        }
    };


    useEffect(() => {
        if (isOpen && getEditUserID?.length > 0) {
            dispatch(getRoles());
            dispatch(get_all_active_departments());
            dispatch(edit_employee_data(getEditUserID));
        }
    }, [isOpen, dispatch, getEditUserID]);

    useEffect(() => {
        if (EditUserDetail) {
            setValue("first_name", EditUserDetail?.first_name);
            setValue("last_name", EditUserDetail?.last_name);
            setValue("email", EditUserDetail?.email);
            setValue("phone", EditUserDetail?.phone);
            setValue("user_name", EditUserDetail?.user_name);
            setValue("employee_id", EditUserDetail?.employee_id);
            setValue("date_of_birth", EditUserDetail?.date_of_birth);
            setValue("is_active", EditUserDetail?.is_active === true ? "1" : "0");
            setValue("profile_pic", EditUserDetail?.profile_pic);
            setValue("designation_id", EditUserDetail?.designation_id);
            setValue("department_id", EditUserDetail?.department_id);
            setValue("role_id", EditUserDetail?.role_id);
        }
    }, [EditUserDetail, setValue]);
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
    };

    return (
        <>

            <div id="edit_employee" className="modal custom-modal fade" role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Employee</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setIsOpen(false)}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                First Name <span className="text-danger">*</span>
                                            </label>
                                            <input className="form-control" type="text"  {...register("first_name", { required: "First name is required" })} />
                                            {errors.first_name && (
                                                <small className="text-danger">{errors.first_name.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Last Name</label>
                                            <input className="form-control" type="text" {...register("last_name", { required: "Last name is required" })} />
                                            {errors.last_name && (
                                                <small className="text-danger">{errors.last_name.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Username <span className="text-danger">*</span>
                                            </label>
                                            <input className="form-control" type="text" {...register("user_name", { required: "User name is required" })} />
                                            {errors.user_name && (
                                                <small className="text-danger">{errors.user_name.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Email <span className="text-danger">*</span>
                                            </label>
                                            <input className="form-control" type="email" {...register("email", { required: "Email is required" })} />
                                            {errors.email && (
                                                <small className="text-danger">{errors.email.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Password </label>
                                            <input className="form-control" type="password"  {...register("password")} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Confirm Password</label>
                                            <input className="form-control" type="password" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Profile Pic <span className="text-danger">*</span>
                                            </label>
                                            <input type="file" className="form-control" onChange={handleFileChange} />
                                            {errors.profile_pic && (
                                                <small className="text-danger">{errors.profile_pic.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                DOB <span className="text-danger">*</span>
                                            </label>
                                            <div className="cal-icon">
                                                <input className="form-control floating datetimepicker" type="date" id="birthday" name="birthday"  {...register("date_of_birth", { required: "Date of birth is required" })} maxDate={maxDate}
                                                    minDate={minDate} />
                                                {errors.date_of_birth && (
                                                    <small className="text-danger">{errors.date_of_birth.message}</small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Phone </label>
                                            <input className="form-control" type="text"  {...register("phone", { required: "Phone number is required" })} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Is Active</label>
                                            <select
                                                className="form-control form-select"
                                                {...register("is_active", { required: "Is Active is required" })}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                            {errors.is_active && (
                                                <small className="text-danger">{errors.is_active.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Department <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-control form-select"
                                                {...register("department_id", {
                                                    required: "Department is required", onChange: (e) => {
                                                        setValue('department_id', e.target.value);
                                                    },
                                                })}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    ActiveDepartments && ActiveDepartments?.map((ele) => (
                                                        <option value={ele?.id}>{ele?.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.department_id && (
                                                <small className="text-danger">{errors.department_id.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Designation <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-control form-select"
                                                {...register("designation_id", { required: "Designation is required" })}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    ActiveDesignation && ActiveDesignation?.map((ele) => (
                                                        <option value={ele?.id}>{ele?.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.designation_id && (
                                                <small className="text-danger">{errors.designation_id.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Role <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-control form-select"
                                                {...register("role_id", { required: "Role is required" })}
                                                disabled>
                                                <option value="">Select</option>
                                                {
                                                    RolesList && RolesList?.map((ele) => (
                                                        <option value={ele?.id}>{ele?.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.role_id && (
                                                <small className="text-danger">{errors.role_id.message}</small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button
                                        className="btn btn-primary submit-btn"
                                        // data-bs-dismiss="modal"
                                        // aria-label="Close"
                                        // type="reset"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllEmployeeEditPopup;
