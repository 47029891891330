import { createSlice } from "@reduxjs/toolkit";
import { get_all_active_designation, Add_designation, edit_designation_data, get_designation_data } from "../services/Designation";

const initialState = {
    isLoading: false,
    error: null,
    token: localStorage.getItem('token'),
    errorMsg: '',
    ActiveDesignation: [],
    EditDesignationData: [],
    designationEditId: {},
    AllDesignation: []
};

export const DesignationSlice = createSlice({
    name: "designation",
    initialState,
    reducers: {
        setDesignationEditID: (state, action) => {
            state.designationEditId = action?.payload;
        },
    },
    extraReducers: (builder) => {
        // // ========== Add_historian ============== //
        builder.addCase(Add_designation.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_designation.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMsg = action?.payload;
            state.logUserID = action?.payload?.data?.data?.id
        });
        builder.addCase(Add_designation.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
            state.errorMsg = action?.payload;
        });
        // ===========get active designation data ============== //
        builder.addCase(get_all_active_designation.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_active_designation.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.ActiveDesignation = action?.payload?.data
        });
        builder.addCase(get_all_active_designation.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit designation data ============== //
        builder.addCase(edit_designation_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_designation_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditDesignationData = action?.payload?.data
        });
        builder.addCase(edit_designation_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ===========get all designation data ============== //
        builder.addCase(get_designation_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_designation_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllDesignation = action?.payload?.data
        });
        builder.addCase(get_designation_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }

});
export const { setDesignationEditID } = DesignationSlice?.actions;
export default DesignationSlice.reducer;