import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user";
import historianReducer from "./Historian";
import commonReducer from "./Common";
import departmentReducer from './Department';
import designationReducer from './Designation';
import employeeReducer from './Employee';
const rootReducer = combineReducers({
    auth: userReducer,
    historian: historianReducer,
    department: departmentReducer,
    designation: designationReducer,
    employee: employeeReducer,
    common: commonReducer,
});
export default rootReducer;