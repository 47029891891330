import React, { useRef } from "react";
import { Applogo } from "../../../Routes/ImagePath";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../Redux/services/User";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

const ChangePassword = () => {
  const { register, handleSubmit, getValues, formState: { errors }, watch
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    const payload = {
      password: data.password
    }
    try {
      const response = await dispatch(resetPassword(payload));
      if (response?.payload?.status) {
        navigate("/");
      }
    } catch (error) {
    }
  };
  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* Account Logo */}
            <div className="account-logo">
              <Link to="/admin-dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Change Password</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* <div className="input-block mb-3">
                    <label className="col-form-label">Old password</label>
                    <input type="password" className="form-control" />
                  </div> */}
                  <div className="input-block mb-3">
                    <label className="col-form-label">New password</label>
                    <input type="password" className="form-control"  {...register('password', { required: "Password is required" })} />
                    <span className="text-danger">{errors.password?.message}</span>
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">Confirm password</label>
                    <input type="password" className="form-control" {...register('confirm_password', {
                      required: "Confirm password is required",
                      validate: value =>
                        value === password.current || "Password and confirm password do not match"
                    })} />
                    <span className="text-danger">{errors.confirm_password?.message}</span>
                  </div>
                  <div className="submit-section mb-4">
                    <button
                      className="btn btn-primary submit-btn"
                    >
                      Update Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
