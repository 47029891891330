import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./features";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  devTools: true
});
const persistor = persistStore(store);
export { store, persistor };