import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import AppContainer from "../Appcontainer";
import Login from "../../views/pages/Authentication/Login";
import Register from "../../views/pages/Authentication/Register";
import Otp from "../../views/pages/Authentication/Otp";
import Error404 from "../../views/pages/Error/Error404";
import Error500 from "../../views/pages/Error/Error500";
import JobList from "../../views/pages/Authentication/JobList";
import JobView from "../../views/pages/Authentication/JobView";
import ChangePassword from "../../views/pages/Authentication/ChangePassword";
import ForgotPassword from "../../views/pages/Authentication/ForgotPassword";
import LockScreen from "../../views/pages/Authentication/LockScreen";

import { Navigate } from "react-router-dom/dist";
import ComingSoon from "../../views/pages/Pages/ComingSoon";
import UnderManitenance from "../../views/pages/Pages/UnderManitenance";

const AppRouter = () => {
  useEffect(() => {
    localStorage.setItem("email", "admin@bilvaleaf.com");
    localStorage.setItem("password", "Bilvaleaf2024@!!");
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-500" element={<Error500 />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/under-maintenance" element={<UnderManitenance />} />

        <Route path="/job-list" element={<JobList />} />
        <Route path="/job-view" element={<JobView />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/lock-screen" element={<LockScreen />} />
        <Route path="/*" element={<AppContainer />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
