import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { getSubCategories } from "../../../Redux/services/common";
import { useDispatch } from "react-redux";
import { Add_historian } from "../../../Redux/services/Historian";
import { useSelector } from "react-redux";

const AddHistorian = () => {
    const dispatch = useDispatch();
    const subCategoryData = useSelector(state => state?.common?.errorMsg?.data);
    const { register, control, handleSubmit, formState: { errors }, } = useForm({ defaultValues: { fields: [{ name: '', category: '' }] } });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fields'
    });
    const onSubmit = async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key !== 'thumbnail' && key !== 'main_img' && key !== 'fields' && key !== 'title' && key !== 'paragraph') {
                formData.append(key, data[key]);
            }
        });
        if (data.thumbnail?.[0]) {
            formData.append('thumbnail', data.thumbnail[0]);
        }
        if (data.main_img?.[0]) {
            formData.append('main_img', data.main_img[0]);
        }
        const { fields, title, paragraph, ...rest } = data;
        const jsonData = {
            title: title,
            paragraph: paragraph,
            inner_data: fields.map(field => ({
                title: field.title,
                paragraph: field.paragraph
            }))
        };
        formData.append('json_data', JSON.stringify(jsonData));
        try {
            const response = await dispatch(Add_historian(formData));
            if (response?.payload?.status === true) {
            }
        } catch (error) {
            console.error("Error during add home:", error);
        }
    };




    useEffect(() => {
        dispatch(getSubCategories())
    }, [])
    return (
        <div id="add_user" className="modal custom-modal fade" role="dialog">
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Historian</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                {/* Sub-Category */}
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Sub-Category</label>
                                        <select
                                            className="form-control"
                                            {...register("sub_category_id", { required: "Sub-Category is required" })}
                                        >
                                            <option value="">Select</option>
                                            {
                                                subCategoryData && subCategoryData?.map((ele) => (
                                                    <option value={ele?.id}>{ele?.name}</option>
                                                ))
                                            }
                                        </select>
                                        {errors.sub_category_id && (
                                            <small className="text-danger">{errors.sub_category_id.message}</small>
                                        )}
                                    </div>
                                </div>
                                {/* Blog Title */}
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Blog Title</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("blog_title", { required: "Blog Title is required" })}
                                        />
                                        {errors.blog_title && (
                                            <small className="text-danger">{errors.blog_title.message}</small>
                                        )}
                                    </div>
                                </div>
                                {/* Meta Description */}
                                <div className="col-sm-12">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Meta Description</label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            {...register("meta_description", { required: "Meta Description is required" })}
                                        ></textarea>
                                        {errors.meta_description && (
                                            <small className="text-danger">{errors.meta_description.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            First Paragraph
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            {...register('first_paragraph', { required: `First paragraph is required` })}
                                        ></textarea>
                                        {errors?.first_paragraph && (
                                            <small className="text-danger">{errors?.first_paragraph?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            First Heading
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register('first_heading', { required: ` First heading is required` })}
                                        />
                                        {errors?.first_heading && (
                                            <small className="text-danger">{errors?.first_heading?.message}</small>
                                        )}
                                    </div>
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            First Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            {...register('first_description', { required: ` First description is required` })}
                                        ></textarea>
                                        {errors?.first_description && (
                                            <small className="text-danger">{errors?.first_description?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Second Heading
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register('second_heading', { required: `Second heading is required` })}
                                        />
                                        {errors?.second_heading && (
                                            <small className="text-danger">{errors?.second_heading?.message}</small>
                                        )}
                                    </div>
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Second Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            {...register('second_heading', { required: ` Second description is required` })}
                                        ></textarea>
                                        {errors?.second_heading && (
                                            <small className="text-danger">{errors?.second_heading?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Third Heading
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register('third_heading', { required: `Third heading is required` })}
                                        />
                                        {errors?.third_heading && (
                                            <small className="text-danger">{errors?.third_heading?.message}</small>
                                        )}
                                    </div>
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Third Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            {...register('third_description', { required: `Third description is required` })}
                                        ></textarea>
                                        {errors?.third_description && (
                                            <small className="text-danger">{errors?.third_description?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Fourth Heading
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register('fourth_heading', { required: `Fourth heading is required` })}
                                        />
                                        {errors?.fourth_heading && (
                                            <small className="text-danger">{errors?.fourth_heading?.message}</small>
                                        )}
                                    </div>
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Fourth Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            {...register('fourth_description', { required: `Fourth description is required` })}
                                        ></textarea>
                                        {errors?.fourth_description && (
                                            <small className="text-danger">{errors?.fourth_description?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Conclusion
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register('conclusion', { required: `conclusion is required` })}
                                        />
                                        {errors?.conclusion && (
                                            <small className="text-danger">{errors?.conclusion?.message}</small>
                                        )}
                                    </div>
                                </div>
                                {/* Keywords */}
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Keywords</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("keywords", { required: "Keywords are required" })}
                                        />
                                        {errors.keywords && (
                                            <small className="text-danger">{errors.keywords.message}</small>
                                        )}
                                    </div>
                                </div>

                                {/* Thumbnail */}
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Thumbnail</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            {...register("thumbnail", { required: "Thumbnail is required" })}
                                        />
                                        {errors.thumbnail && (
                                            <small className="text-danger">{errors.thumbnail.message}</small>
                                        )}
                                    </div>
                                </div>

                                {/* Main Image */}
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">Main Image</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            {...register("main_img", { required: "Main Image is required" })}
                                        />
                                        {errors.main_img && (
                                            <small className="text-danger">{errors.main_img.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Is Active
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register('is_active', { required: `Is active is required` })}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {errors?.is_active && (
                                            <small className="text-danger">{errors?.is_active?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Is Tranding
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register('is_tranding', { required: `Is Tranding is required` })}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {errors?.is_tranding && (
                                            <small className="text-danger">{errors?.is_tranding?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Is Featured
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register('is_featured', { required: `Is featured is required` })}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {errors?.is_featured && (
                                            <small className="text-danger">{errors?.is_featured?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">json title</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register(`title`, { required: "Title is required" })}
                                        />
                                        {errors.title && (
                                            <small className="text-danger">{errors.title.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">json Paragraph</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register(`paragraph`, { required: "Paragraph is required" })}
                                        />
                                        {errors?.paragraph && (
                                            <small className="text-danger">{errors.paragraph.message}</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Dynamic Fields */}
                            {fields.map((field, index) => (
                                <div key={field.id} className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Title</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                {...register(`fields.${index}.title`, { required: "Title is required" })}
                                            />
                                            {errors.fields?.[index]?.title && (
                                                <small className="text-danger">{errors.fields[index].title.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Paragraph</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                {...register(`fields.${index}.paragraph`, { required: "Paragraph is required" })}
                                            />
                                            {errors.fields?.[index]?.paragraph && (
                                                <small className="text-danger">{errors.fields[index].paragraph.message}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <button
                                            type="button"
                                            className="btn btn-danger mb-3"
                                            onClick={() => remove(index)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className="col-sm-12">
                                <button
                                    type="button"
                                    className="btn btn-primary mb-3"
                                    onClick={() => append({ title: "", paragraph: "" })}
                                >
                                    Add More
                                </button>
                            </div>

                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddHistorian;
