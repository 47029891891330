import { Table } from "antd";
import React, { useEffect, useState } from "react";
import {
    Avatar_01,
    Avatar_02,
    Avatar_03,
    Avatar_04,
    Avatar_05,
    Avatar_06,
    Avatar_07,
    Avatar_08,
    Avatar_09,
    Avatar_10,
    Avatar_11,
    Avatar_12,
} from "../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import EditHistorian from "../../../../components/Administration/Historian/EditHistorianModal";
import DeleteModal from "../../../../components/modelpopup/deletePopup";
import { edit_historian_data, get_historian_data } from "../../../../Redux/services/Historian";
import { useDispatch, useSelector } from "react-redux";
const HistorianTable = () => {
    const dispatch = useDispatch();
    const [selectedHistorianId, setSelectedHistorianId] = useState('');
    const historianList = useSelector(state => state?.historian?.historianData);

    useEffect(() => {
        dispatch(get_historian_data());
    }, [])
    const data = historianList?.map(item => ({
        id: item?.id,
        title: item?.title,
        is_active: item?.is_active === true ? 'true' : 'false',
        is_featured: item?.is_featured === true ? 'true' : 'false',
        is_tranding: item?.is_tranding === true ? 'true' : 'false',
        subCategory: item.subCategory?.name || '-'
    }));
    useEffect(() => {
        if (selectedHistorianId) {
            dispatch(edit_historian_data(selectedHistorianId));

            // Check if Bootstrap JS is available
            if (window.bootstrap) {
                const modalElement = new window.bootstrap.Modal(document.getElementById('edit_user'));
                modalElement.show(); // Open modal when ID is set
            } else {
                console.error('Bootstrap JS is not loaded');
            }
        }
    }, [selectedHistorianId]);

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            render: (text, record) => (
                <div className="table-avatar">
                    {/* <Link to="/profile" className="avatar">
                        <img alt="" src={record.image} />
                    </Link> */}
                    <Link to="/profile">
                        {text} <span>{record.role}</span>
                    </Link>
                </div>
            ),
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: "Is active",
            dataIndex: "is_active",
            sorter: (a, b) => a.is_active.length - b.is_active.length,
        },

        {
            title: "Is featured",
            dataIndex: "is_featured",
            sorter: (a, b) => a.is_featured.length - b.is_featured.length,
        },

        {
            title: "Is tranding",
            dataIndex: "is_tranding",
            sorter: (a, b) => a.is_tranding.length - b.is_tranding.length,
        },
        {
            title: "Sub Category",
            dataIndex: "subCategory",
            sorter: (a, b) => a.subCategory.length - b.subCategory.length,
        },
        // {
        //     title: "Role",
        //     dataIndex: "role",
        //     render: (text) => (
        //         <span
        //             className={
        //                 text === "Admin"
        //                     ? "badge bg-inverse-danger"
        //                     : "badge bg-inverse-success"
        //             }
        //         >
        //             {text}
        //         </span>
        //     ),
        //     sorter: (a, b) => a.role.length - b.role.length,
        // },
        {
            title: "Action",
            render: (text, record) => (
                <div className="dropdown dropdown-action text-end">
                    <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link
                            className="dropdown-item"
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_user"
                            onClick={() => setSelectedHistorianId(record.id)}
                        >
                            <i className="fa fa-pencil m-r-5" /> Edit
                        </Link>
                        <Link
                            className="dropdown-item"
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                        >
                            <i className="fa fa-trash m-r-5" /> Delete
                        </Link>
                    </div>
                </div>
            ),
        },
    ];
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <Table
                        className="table-striped"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={data}
                        rowKey={(record) => record.id}
                    />
                    {selectedHistorianId && <EditHistorian id={selectedHistorianId} />}
                    <DeleteModal Name="Delete" />
                </div>
            </div>
        </div>
    );
};

export default HistorianTable;
